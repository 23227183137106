import axios from '../http/axios'

export const apiPostSignIn = params => {
	return axios.post('/user/login', params)
}

export const apiGetVerifyCodeRegister = phone => {
	return axios.get('/user/verify-code/register', { params: phone })
}

export const apiGetRegisterStatus = phone => {
	return axios.get('/user/register-status', { params: phone })
}

export const apiPostRegister = params => {
	return axios.post('/user/register', params)
}

export const apiPutChangePassword = params => {
	return axios.put('/user/change-password', params)
}

export const apiGetVerifyCodeResetPassword = params => {
	return axios.get('/user/verify-code/reset-password', { params })
}

export const apiPutUserResetPassword = params => {
	return axios.put('/user/reset-password', params)
}

export const apiPostLogout = () => {
	return axios.post('/user/logout')
}

export const apiPostCheckCode = params => {
	return axios.post('/user/verify-code/reset-password/test', params)
}
