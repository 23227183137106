<template>
    <div class="home">
        <MenuBar />
        <Main />
    </div>
</template>

<script>
import MenuBar from '../components/MenuBar'
import Main from './Main'

export default {
	name: 'Home',
	components: {
		Main,
		MenuBar
	}
}
</script>

<style scoped lang="less">
.home {
    min-height: 100vh;
    // min-height: 600px;
    position: relative;
    background-color: #f0f2f5;
}
</style>