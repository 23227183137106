import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home'

Vue.use(Router)

const routes = [
	{
		path: '/',
		name: 'login',
		meta: {
			title: '云成企业融资服务平台',
			requiresAuth: false
		},
		/* eslint-disable-next-line */
        component: () => import (/* webpackChunkName: 'login' */
			'../views/user/Login.vue')
	}, {
		path: '/home',
		meta: {
			title: 'Home',
			requiresAuth: false
		},
		component: Home,
		children: [
			{
				path: '/',
				redirect: '/welcome'
			}, {
				path: '/welcome',
				name: 'welcome',
				meta: {
					title: '首页',
					requiresAuth: false
				},
				/* eslint-disable-next-line */
                component: () => import (/* webpackChunkName: 'welcome' */
					'../views/Welcome.vue')
			}, {
				path: '/orderFinancing/orderFinancingApplication',
				name: 'orderFinancingApplication',
				meta: {
					title: '订单融资申请',
					requiresAuth: false
				},
				/* eslint-disable-next-line */
                component: () => import (/* webpackChunkName: 'orderFinancingApplication' */
					'../views/orderFinancing/Create.vue')
			}, {
				path: '/orderFinancing/list',
				name: 'orderFinancingList',
				meta: {
					title: '订单融资列表',
					requiresAuth: false
				},
				/* eslint-disable-next-line */
                component: () => import (/* webpackChunkName: 'orderFinancingList' */
					'../views/orderFinancing/List.vue')
			}, {
				path: '/orderFinancing/detail',
				name: 'orderFinancingDetail',
				meta: {
					title: '订单融资申请详情',
					requiresAuth: false
				},
				/* eslint-disable-next-line */
                component: () => import (/* webpackChunkName: 'orderFinancingDetail' */
					'../views/orderFinancing/Detail.vue')
			}, {
				path: '/accountsReceivable/list',
				name: 'accountsReceivableList',
				meta: {
					title: '应收账款融资列表',
					requiresAuth: false
				},
				/* eslint-disable-next-line */
                component: () => import (/* webpackChunkName: 'accountsReceivableList' */
					'../views/accountsReceivable/List.vue')
			}, {
				path: '/accountsReceivable/financingApplication',
				name: 'financingApplication',
				meta: {
					title: '应收账款融资申请',
					requiresAuth: false
				},
				/* eslint-disable-next-line */
                component: () => import (/* webpackChunkName: 'financingApplication' */
					'../views/accountsReceivable/FinancingApplication.vue')
			}, {
				path: '/accountsReceivable/detail',
				name: 'accountsReceivableDetail',
				meta: {
					title: '应收账款融资详情',
					requiresAuth: false
				},
				/* eslint-disable-next-line */
                component: () => import (/* webpackChunkName: 'financingApplication' */
					'../views/accountsReceivable/Detail.vue')
			}, {
				path: '/billFinancing/list',
				name: 'billFinancingList',
				meta: {
					title: '票据融资',
					requiresAuth: false
				},
				/* eslint-disable-next-line */
                component: () => import (/* webpackChunkName: 'billFinancingList' */
					'../views/billFinancing/List.vue')
			}, {
				path: '/billFinancing/detail',
				name: 'billFinancingDetail',
				meta: {
					title: '票据融资详情',
					requiresAuth: false
				},
				/* eslint-disable-next-line */
                component: () => import (/* webpackChunkName: 'billFinancingList' */
					'../views/billFinancing/Detail.vue')
			}, {
				path: '/billFinancing/billFinancingApplication',
				name: 'billFinancingApplication',
				meta: {
					title: '票据融资申请',
					requiresAuth: false
				},
				/* eslint-disable-next-line */
                component: () => import (/* webpackChunkName: 'billFinancingApplication' */
					'../views/billFinancing/Create.vue')
			}, {
				path: '/accountManagement/changePassword',
				name: 'changePassword',
				meta: {
					title: '修改密码',
					requiresAuth: false
				},
				/* eslint-disable-next-line */
                component: () => import (/* webpackChunkName: 'changePwd' */
					'../views/user/ChangePwd.vue')
			}, {
				path: '/helpCenter',
				name: 'helpCenter',
				meta: {
					title: '帮助中心',
					requiresAuth: false
				},
				/* eslint-disable-next-line */
                component: () => import (/* webpackChunkName: 'helpCenter' */
					'../views/HelpCenter.vue')
			}, {
				path: '*',
				meta: {
					title: '页面未找到',
					requiresAuth: false
				},
				/* eslint-disable-next-line */
                component: () => import (/* webpackChunkName: 'errorPage' */
					'../components/ErrorPage.vue')
			}
		]
	}, {
		path: '*',
		meta: {
			title: '页面未找到',
			requiresAuth: false
		},
		/* eslint-disable-next-line */
        component: () => import (/* webpackChunkName: 'errorPage' */
			'../components/ErrorPage.vue')
	}
]

export default routes
