<template>
    <div :class="collapsed ? 'collapsed' : 'collapse'">
        <div class="hnzb-logo-box">
            <div class="logo"></div>
        </div>
        <a-menu class="menuNav" @openChange="openChange" @click="click" :openKeys="[defaultOpenKeys]" :selectedKeys="[defaultSelectedKeys]" mode="inline" theme="dark" :inlineCollapsed="collapsed">
            <template v-for="item in menu">
                <a-sub-menu v-if="item.children" :key="item.path">
                    <span slot="title" :key="item.path">
                        <a-icon :type="item.icon" />
						<span>{{item.name}}</span>
                    </span>
                    <template v-for="it in item.children">
                        <a-sub-menu v-if="it.children" :key="it.path">
                            <span slot="title" :key="it.path">
                                <span>{{it.name}}</span></span>
                            <template v-for="its in it.children">
                                <a-menu-item :key="its.path">{{its.name}}</a-menu-item>
                            </template>
                        </a-sub-menu>
                        <template v-if="!it.children && !it.showItem">
                            <a-menu-item :key="it.path">
                                <span>{{it.name}}</span>
                            </a-menu-item>
                        </template>
                    </template>
                </a-sub-menu>
                <a-menu-item v-else :key="item.path">
                    <a-icon :type="item.icon" />
                    <span>{{item.name}}</span>
                </a-menu-item>
            </template>
        </a-menu>
    </div>
</template>

<script>
import sideBarMenu from '../assets/js/sideBarMenu'
export default {
	name: 'MenuBar',
	inject: ['reload'],
	data() {
		return {
			getDefaultOpenKeys: '',
			getDefaultSelectedKeys: ''
		}
	},
	computed: {
		menu() {
			return sideBarMenu
		},
		collapsed() {
			return this.$store.state.collapsed
		},
		defaultOpenKeys: {
			get: function () {
				return this.getDefaultOpenKeys
			},
			set: function () {
				return this.getDefaultOpenKeys
			}
		},
		defaultSelectedKeys: {
			get: function () {
				return this.getDefaultSelectedKeys
			},
			set: function () {
				return this.getDefaultSelectedKeys
			}
		}
	},
	watch: {
		'$route.path': {
			handler(val, oldVal) {
				let subPath = ''
				const arr = val.split('/')
				arr[2] ? (subPath = '/' + arr[1] + '/' + arr[2]) : (subPath = '/' + arr[1])
				this.getDefaultOpenKeys = '/' + arr[1]
				this.getDefaultSelectedKeys = subPath
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		click({ item, key, keyPath }) {
			this.$route.path === key ? this.reload() : this.$router.push(key)
		},
		openChange(openKey) {
			this.getDefaultOpenKeys = openKey[1] ? openKey[1] : ''
		}
	}
}
</script>
<style lang="less" scoped>
.collapse {
    width: 256px;
    height: 100%;
    position: absolute;
    float: left;
    transition: all 0.2s;
    .hnzb-logo-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 64px;
        background-color: #002140;
        padding: 16px 26px;
        .logo{
            width: 119px;
            height: 32px;
            background-image: url('../assets/img/logo@2x.png');
            background-size: cover;
        }
    }
}
.collapsed {
    width: 80px;
    height: 100%;
    position: absolute;
    float: left;
    transition: all 0.2s;
    .hnzb-logo-box {
        height: 64px;
        background-color: #002140;
        padding: 16px 26px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        .logo{
            width: 32px;
            height: 32px;
            background-image: url('../assets/img/logomini.png');
            background-size: cover;
        }
    }
}
.menuNav {
    height: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding-top: 64px;
}
</style>
