export const isNull = str => {
	return str === '' || str === 'null' || str === null || str === undefined || str === 'undefined'
}

export const phoneStrReg = /^[1]([1-9])[0-9]{9}$/

export const isTel = /^[0-9|\-|\u4e00-\u9fa5]+$/

export const isEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/

export const isWanMoney = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/

const Utils = {
	isNull,
	phoneStrReg,
	isWanMoney,
	isEmail,
	isTel
}

export default Utils
