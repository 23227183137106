<template>
    <div :class="collapsed ? 'maind' : 'main'">
        <a-alert message="升级提示" v-if="isIE10" description="您的浏览器版本过低 为了更好的体验，请升级您的浏览器....." type="warning" showIcon />
        <header class="header">
            <a-icon class="iconToggled" @click="toggleCollapsed" :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
            <a-dropdown class="fr">
                <span class="ant-dropdown-link">
                    <a-avatar size="small" icon="user" /><span class="username">{{username}}</span>
                    <a-icon type="down" />
                </span>
                <a-menu slot="overlay">
                    <a-menu-item @click="logoutClick">
                        <a-icon type="poweroff" /><span>退出</span>
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </header>
        <div class="content">
            <transition name="slide-fade">
                <router-view />
            </transition>
        </div>
    </div>
</template>

<script>
import Cookies from 'cookies-js'
import { apiPostLogout } from '../assets/js/api/api.user'

export default {
	name: 'Main',
	data: () => ({
		username: Cookies.get('username')
	}),
	computed: {
		collapsed() {
			return this.$store.state.collapsed
		},
		isIE10() {
			if (navigator.appName === 'Microsoft Internet Explorer' && parseInt(navigator.appVersion.split(';')[1].replace(/[ ]/g, '').replace('MSIE', '')) <= 10) {
				return true
			}
			return false
		}
	},
	methods: {
		toggleCollapsed() {
			this.$store.dispatch('collapsedAction', {
				collapsed: !this.$store.state.collapsed
			})
		},
		async logoutClick() {
			const res = await apiPostLogout()
			if (res.error) {
				this.$message.error(res.error.msg)
			} else {
				sessionStorage.removeItem('companyApplyId')
				this.$router.push('/')
			}
		}
	}
}
</script>

<style lang="less" scoped>
.maind {
    margin-left: 80px;
}
.main {
    margin-left: 256px;
}
.header {
        height: 60px;
        padding: 0 40px 0 20px;
        background: #fff;
        border-bottom: 1px solid #ddd;
        .iconToggled {
            margin-top: 20px;
            float: left;
            font-size: 24px;
            cursor: pointer;
        }
    }
    .username {
        padding-right: 6px;
        padding-left: 4px;
        cursor: pointer;
    }
    .fr {
        margin-top: 20px;
        float: right;
    }
    .content{
        height: 100%;
        overflow: auto;
    }
.slide-fade-enter-active {
    animation: fadeIn 0.5s ease-in;
}
.slide-fade-leave-active {
    animation: fadeIn 0s ease-out;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(10px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}
</style>