const menu = [
	{
		name: '首页',
		icon: 'desktop',
		path: '/welcome'
	}, {
		name: '订单融资',
		icon: 'solution',
		path: '/orderFinancing',
		children: [
			{
				name: '订单融资列表',
				icon: 'ordered-list',
				path: '/orderFinancing/list'
			}
			// , {
			// 	name: '订单融资申请',
			// 	icon: 'mail',
			// 	showItem: !Number(sessionStorage.getItem('companyApplyId')) > 0,
			// 	path: '/orderFinancing/orderFinancingApplication'
			// }
		]
	}, {
		name: '应收账款融资',
		icon: 'pay-circle',
		path: '/accountsReceivable',
		children: [
			{
				name: '应收账款融资列表',
				icon: 'ordered-list',
				path: '/accountsReceivable/list'
			}, {
				name: '应收账款融资申请',
				icon: 'plus-circle',
				path: '/accountsReceivable/financingApplication'
			}
		]
	}, {
		name: '票据融资',
		icon: 'file-text',
		path: '/billFinancing',
		children: [
			{
				name: '票据融资列表',
				icon: 'ordered-list',
				path: '/billFinancing/list'
			}, {
				name: '票据融资申请',
				icon: 'plus-circle',
				path: '/billFinancing/billFinancingApplication'
			}
		]
	}, {
		name: '账户管理',
		icon: 'setting',
		path: '/accountManagement',
		children: [
			{
				name: '修改密码',
				icon: 'mail',
				path: '/accountManagement/changePassword'
			}
		]
	}, {
		name: '帮助中心',
		icon: 'tool',
		path: '/helpCenter'
	}
]

export default menu