import Vue from 'vue'
import Router from 'vue-router'
import routes from './route'
import NProgress from 'nprogress'
require('../assets/style/nprogress.css')

Vue.use(Router)

const router = new Router({
	// mode: 'hash',
	mode: 'history',
	base: '/',
	routes,
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	}
})

router.beforeEach((to, from, next) => {
	let isLogin = true
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!isLogin) {
			next({
				path: '/'
			})
		} else {
			NProgress.start()
			next()
		}
	} else {
		NProgress.start()
		next()
	}
})

router.afterEach(route => {
	document.title = route.meta.title
	NProgress.done()
})

export default router
