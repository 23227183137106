import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		collapsed: false
	},
	mutations: {
		collapsedMutation(state, payload) {
			state.collapsed = payload.collapsed
		}
	},
	actions: {
		collapsedAction(context, payload) {
			context.commit('collapsedMutation', payload)
		}
	}
})
