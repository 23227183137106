<template>
    <div id="app">
        <LocaleProvider :locale="locale">
            <template>
                <router-view v-if="isRouterAlive" />
            </template>
        </LocaleProvider>
    </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { LocaleProvider } from 'ant-design-vue'

export default {
	name: 'App',
	provide() {
		return {
			reload: this.reload
		}
	},
	data: () => {
		return {
			isRouterAlive: true,
			locale: zhCN
		}
	},
	methods: {
		reload() {
			this.isRouterAlive = false
			this.$nextTick(function () {
				this.isRouterAlive = true
			})
		}
	},
	components: {
		LocaleProvider
	}
}
</script>

<style lang="less">
@import url("./assets/style/common.less");
#app {
    position: relative;
    height: 100vh;
    min-height: 600px;
    min-width: 1200px;
    font-family: "PingFang SC", "Avenir", Helvetica, Arial, sans-serif;
}
.well-layout {
    margin: 16px;
    background-color: #fff;
    border-radius: 4px;
}
.mini-h{
    min-height: 495px;
    min-height: calc(100vh - 130px - 32px)
}
.fl {
    float: left;
}
.fr {
    float: right;
}
.mt10 {
    margin-top: 10px;
}
.mt20 {
    margin-top: 20px;
}
.ml10 {
    margin-left: 10px;
}
.mr10 {
    margin-right: 10px;
}
.pb {
    padding: 24px;
}
hr,
.hr {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.08);
}
.bgf {
    background-color: #fff;
}
.clear {
    zoom: 1;
    clear: both;
    &::after,
    &::before {
        zoom: 1;
        display: block;
        clear: both;
        content: ".";
        visibility: hidden;
        height: 0;
        font-size: 0;
    }
}
.title {
    padding: 16px 32px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 16px;
}
.tips-list {
    padding: 6px 32px;
    .li {
        margin: 16px 0;
        line-height: 100%;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        list-style: none;
        padding-left: 12px;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: rgba(89, 89, 89, 1);
            top: 50%;
            left: 0;
            margin-top: -2px;
        }
    }
}
.detail-page > .ant-form-item {
    margin-bottom: 0;
}
.detail-page {
    .ant-form-item-children{
        span{
            display: block;
            max-width: 400px;
            text-align: left;
            word-wrap: break-word;
            word-break: normal;
            overflow: hidden;
            white-space: normal;
            line-height: 20px;
            margin-top: 9px;
        }
    }
}
</style>
