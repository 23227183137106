export default {
	// method: 'post',
	baseURL: '/api',
	headers: {
		// 'Content-Type': 'application/x-www-form-urlencoded'
		'Content-Type': 'application/json;charset=UTF-8'
	},
	data: {},
	timeout: 5000,
	withCredentials: false,
	responseType: 'json'
}